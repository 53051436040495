<template>
  <div class="platform-consume-index">
    <div
      class="custom-table border-radius-6 box-shadow-light-grey padding-20"
      style="padding-top: 16px"
    >
      <div class="flex-between operation padding-bottom-20">
        <div>
          <el-select
            v-model="currentOfficial"
            class="margin-right-twentyFour"
            placeholder="请选择公众号"
            style="width: 130px"
            filterable
            @change="handleOfficialChange"
            value-key="id"
          >
            <el-option
              v-for="(item, index) in officialList"
              :key="index"
              :label="item.channelName"
              :value="item"
            >
              <span style="float: left">{{ item.channelName }}</span>
              <span style="float: right; font-size: 13px"
                >{{ item.platformStr }} - {{ item.uname }}</span
              >
            </el-option>
          </el-select>
          <el-date-picker
            class="margin-right-twentyFour"
            v-model="selectTime"
            type="daterange"
            align="right"
            unlink-panels
            clearable
            range-separator="-"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="handlePageChange(1)"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
          <el-select
            v-model="labelType"
            class="margin-right-twentyFour"
            clearable
            placeholder="请选择标签"
            @change="handlePageChange(1)"
          >
            <el-option
              v-for="(item, index) in labelListShow"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button class="margin-right-twentyFour" @click="getList"
            >刷新数据</el-button
          >
          <el-button class="custom-button-margin" type="info" @click="resetData"
            >重置</el-button
          >
        </div>
        <el-button
          type="primary"
          @click="toEdit(null)"
          :disabled="typeof labelType == 'number' ? false : true"
          >新增数据</el-button
        >
      </div>
      <el-table
        v-loading="loading"
        :data="consumeList"
        height="calc(100vh - 248px)"
        style="width: 100%"
      >
        <el-table-column prop="id" label="ID" width="70"> </el-table-column>
        <el-table-column prop="selectDate" min-width="100" label="消耗日期">
        </el-table-column>
        <el-table-column min-width="80" prop="costAmount" label="消耗金额">
          <template slot-scope="scope">
            {{ formatThousands(scope.row.costAmount) }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="cashBase" min-width="80" label="现金基数">
        </el-table-column> -->
        <el-table-column min-width="100" prop="bookName" label="书籍名">
          <template slot-scope="scope">
            <span class="link-text">{{ scope.row.bookName }}</span>
          </template>
        </el-table-column>
        <!-- labelListShow[scope.row.labelType].label -->
        <el-table-column min-width="80" prop="labelType" label="标签类型">
          <template slot-scope="scope">
            <el-tag :style="getTypeColor(scope.row.labelType)">{{
              labelList[scope.row.labelType].label
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="expPv" min-width="80" label="曝光次数">
        </el-table-column>
        <el-table-column prop="clkPv" min-width="80" label="点击次数">
        </el-table-column>
        <el-table-column prop="ctr" min-width="80" label="点击率">
          <template slot-scope="scope">
            <span style="color: #fa675c">{{ scope.row.ctr }}%</span>
          </template>
        </el-table-column>
        <el-table-column min-width="100" prop="ocpmComindex" label="转移目标量">
        </el-table-column>
        <el-table-column
          min-width="100"
          prop="convIndexCpa"
          label="转化目标成本"
        >
        </el-table-column>
        <el-table-column min-width="80" prop="convIndexCvr" label="目标转化率">
          <template slot-scope="scope">
            <span style="color: #fa675c">{{ scope.row.convIndexCvr }}%</span>
          </template>
        </el-table-column>
        <el-table-column prop="comindex" min-width="80" label="公众号关注数">
        </el-table-column>
        <el-table-column prop="action" width="150" label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="toEdit(scope.row)"
              class="margin-right-ten button-small"
              >编辑</el-button
            >
            <el-button
              type="danger"
              class="button-small"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 20px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="30"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <edit
        :show="isEdit"
        :info="current"
        @close="isEdit = false"
        @refresh="handlePageChange(1)"
        :accountChannelId="currentId"
        :labelType="labelType"
      />
    </div>
  </div>
</template>

<script>
import Edit from "./edit";
import { getConsumeList, deleteConsume } from "@/api/account";
import { pickerOptions, labelList } from "@/assets/js/options";
import { formatThousands } from "@/assets/js/utils";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Consume",
  data() {
    return {
      loading: false,
      consumeList: [],
      page: 1,
      total: 0,
      isEdit: false,
      current: null,
      pickerOptions,
      formatThousands,
      labelList,
      currentOfficial: null,
      currentId: null,
      labelType: null,
      selectTime: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.init());
  },
  watch: {
    currentId(newVal) {
      if (!newVal) {
        this.consumeList = [];
      }
    },
  },
  computed: {
    labelListShow() {
      return this.labelList.filter((item) => {
        return item.show;
      });
    },
    ...mapGetters(["officialList"]),
  },
  methods: {
    init() {
      this.currentId = window.localStorage.getItem("official")
        ? Number(window.localStorage.getItem("official"))
        : this.officialList[0].id;
      this.currentOfficial = this.officialList.find(
        (item) => item.id === this.currentId
      );
      this.getList();
    },
    toEdit(data) {
      this.current = data;
      this.isEdit = true;
    },

    handleDelete(data) {
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteConsume(data.id).then(() => {
            this.$message.success("删除成功");
            this.getList();
          });
        })
        .catch(() => {});
    },
    handlePageChange(pages) {
      this.page = pages;
      this.getList();
    },
    getList() {
      this.loading = true;
      getConsumeList(this.currentId, {
        page: this.page,
        startDate: this.selectTime ? this.selectTime[0] : null,
        endDate: this.selectTime ? this.selectTime[1] : null,
        pageSize: 30,
        labelType: this.labelType,
      })
        .then((res) => {
          this.consumeList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleOfficialChange(select) {
      this.currentId = select.id;
      this.getList();
      localStorage.setItem("official", JSON.stringify(select.id));
    },
    getTypeColor(type) {
      const colorList = [
        "rgba(253,93,90)",
        "rgba(255,184,94)",
        "rgba(0,191,138)",
        "rgba(63,128,255)",
        "rgba(98,115,224)",
        "rgba(108,129,152)",
        "rgba(255,122,184)",
        "rgba(34,199,177)",
        "rgba(171,116,233)",
        "rgba(183,183,183),",
        "rgba(183,183,183),",
        "rgba(183,183,183),",
        "rgba(183,183,183),",
        "rgba(183,183,183),",
        "rgba(183,183,183),",
        "rgba(183,183,183),",
      ];
      const regExp = /\d+/g;
      const colorInfo = colorList.map((item) => {
        return item.match(regExp).join(",");
      });
      return {
        color: `rgba(${colorInfo[type]})`,
        backgroundColor: "#fff",
        borderColor: `rgba(${colorInfo[type]})`,
      };
    },
    resetData() {
      this.current = null;
      this.labelType = null;
      this.selectTime = null;
      this.handlePageChange(1);
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
  },
  components: {
    Edit,
  },
};
</script>

<style lang="scss" scoped>
.platform-consume-index {
  // background:#fff;
  // padding:10px 20px 10px 20px;
  // margin-top:16px;
  height: 100%;
  .operation {
    background: #fff;
    box-sizing: border-box;
    // padding:  0 20px;
  }
  .custom-table {
    background: #fff;
  }
}
</style>
